import {Injectable} from '@angular/core';
import { Component, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
 import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router';
declare var iziToast:any;
@Injectable({
    providedIn: 'root'
    })
export class ServerService {
    show:Subject<any> = new Subject();
    qLogin:Subject<any> = new Subject();
    profile:Subject<any> = new Subject();
    showChat:Subject<any> = new Subject();
    showvedioDialer:Subject<any> = new Subject();
    // attendCall:Subject<any> = new Subject();
    currentMessage = new BehaviorSubject(null);
    changeDetectionEmitter: EventEmitter<any> = new EventEmitter<any>();
    constructor(private http:HttpClient,public router:Router){
           
         
    }
    admin_id;
    
    sendServer(postData:any[]) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
     this.admin_id= localStorage.getItem('admin_id');
    //   if(this.admin_id == "64")
    //         return this.http.post("https://omnininja.mconnectapps.com/api/v1.0/index.php", postData,httpOptions);
    //   else
            return this.http.post("https://omnininja.mconnectapps.com/api/v1.0/index.php", postData,httpOptions);
        // return this.http.post("https://" + window.location.hostname + "/api/v1.0/index.php", postData,httpOptions);

    } 
    sendServer2(postData:any[]) {    
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };  
        return this.http.post("https://omnininja.mconnectapps.com/api/v1.0/index.php", postData,httpOptions);
       } 

    sendServer3(postData:any[],url) {    
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };  
        // alert(url)
        return this.http.post(""+url+"", postData,httpOptions);
       } 

    requestPermission() {
       
        }
    receiveMessage() {
         
        }








    pagination(list_info){
        var start,eu,next,back,limit,total_count,offset,last_val,last_final_val,current,pagination,btn_length;
        limit = list_info.page_limit;
        total_count = list_info.total;
        offset = list_info.offset;
        start = 0 + offset;
        eu = start-0;
        if(total_count<start+1 && total_count>1){

            eu = start-limit;
            start = eu;
        }
        current = eu + limit;
        back = eu - limit;
        next = eu + limit;
        last_val = Math.ceil(total_count/limit);
        last_final_val = (last_val-1)*limit;
        pagination = {"info":"hide"};
        if(total_count > limit){
            pagination.info = "show";
            pagination.start = 0;

            if(back >= 0){
                pagination.back = back;
                pagination.backtab = "show";
            }
            else{
                pagination.backtab = "hide";
            }
        
            btn_length = 1;
            pagination.data = []
            for(var offset_count = 0;offset_count < total_count;offset_count=offset_count+limit){

                if((offset_count<=eu+(2*limit)) && (offset_count>=eu-(2*limit))){

                    if(offset_count != eu){
                        pagination.data.push({"btn_length":btn_length,"offset_count":offset_count,"load":true});
                    }
                    else{
                        pagination.data.push({"btn_length":btn_length,"offset_count":offset_count,"load":false});
                    }
                
                }
                 btn_length=btn_length+1;

            }
            if(current < total_count){
                pagination.next = next;
                pagination.nexttab = "show";
            }
            else{
                pagination.nexttab = "hide";
            }
            pagination.end = last_final_val;

        }

        return pagination;
    }





}