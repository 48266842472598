import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule }  from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';  
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPwdComponent } from './auth/forgot-pwd/forgot-pwd.component';
import { LogoutComponent } from './auth/logout.component';
 import { SettingMenuComponent } from './setting-menu/setting-menu.component';
import { ServerService } from './services/server.service';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { GlobalSettingsComponent } from './global-settings/global-settings.component'; 
import { AgentSettingaComponent } from './agent-settinga/agent-settinga.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';

import { SafePipe } from './safe.pipe'; 
import {DatePipe} from '@angular/common';   
import {DragDropModule} from '@angular/cdk/drag-drop'; 
import { GdexWallboardComponent } from './gdex-wallboard/gdex-wallboard.component';
import { SurveyReportComponent } from './survey-report/survey-report.component';
import { SurveySummaryReportComponent } from './survey-summary-report/survey-summary-report.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';


// const config = new AuthServiceConfig([
//     {
//       id: FacebookLoginProvider.PROVIDER_ID,
//       provider: new FacebookLoginProvider('228820218341303')
//     }
//   ]);
  
//   export function provideConfig() {
//     return config;
//   }
const appRoutes: Routes = [
   
    {
        path :'', component:ProfileComponent
    },
    {
      
        path :'login', component:LoginComponent
    },{
        path :'forgot-pwd', component:ForgotPwdComponent
    },
     
    {
        path :'admin-settings', component:AdminSettingsComponent
    },
    {
        path :'logout', component:LogoutComponent
    },
    {
        path :'global-settings', component:GlobalSettingsComponent
    },
    {
      path :'agent-settings', component:AgentSettingaComponent
  },
  {
    path :'survey-report', component:SurveyReportComponent
},
{
    path :'survey-summary-report', component:SurveySummaryReportComponent
},
    {
     
        path :'mc', component:ProfileComponent
    },{
    path :'profile', component:ProfileComponent
  },{
    path :'report-admin', component:ReportComponent
}
// , {
//   path :'app-settings', component:AppSettingsComponent
// }
];

    
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
     AuthComponent,
    LoginComponent,
    ForgotPwdComponent,
  
    SettingMenuComponent,
    LogoutComponent,
    AdminSettingsComponent,       
    GlobalSettingsComponent,
    SafePipe,     
    GdexWallboardComponent,
    AppSettingsComponent,
    AgentSettingaComponent,
    SurveyReportComponent,
    SurveySummaryReportComponent,
    ProfileComponent,
    ReportComponent
    
  
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,FormsModule,
    HttpClientModule,
    // SocialLoginModule,
 
    RouterModule.forRoot(appRoutes,{ useHash: true }),
   
     //RichTextEditorAllModule,
    DragDropModule
  ],
  providers: [DatePipe,
//     {
//     provide: AuthServiceConfig,
//     useFactory: provideConfig
//   },
  Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
