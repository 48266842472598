import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-gdex-wallboard',
  templateUrl: './gdex-wallboard.component.html',
  styleUrls: ['./gdex-wallboard.component.css']
})
export class GdexWallboardComponent implements OnInit { 
     
   
    websocket;
    socketData;
    user_type;
    loginUser;    
     
    droped = 0;
    
    admin_id;
    has_hard_id;
    hardware_id;
    show_act_wall = false;
    has_admin =false;
    has_user =false;
    doc_link;
    admin_permit;
    intervalvalue=0;
    answered =0;
    abandoned=0;
    agentbusy=0;
    total=0;
    waitingtime=0;
    waiting=0;
    talktime=0;

    constructor(private serverService: ServerService, private router:Router) {  }
  
    ngOnInit() {
      this.user_type = localStorage.getItem('user_type');
      this.loginUser = localStorage.getItem('userId');
      this.admin_id = localStorage.getItem('admin_id');
      this.admin_permit=localStorage.getItem('admin_permision');
  
  
      this.user_type = localStorage.getItem('user_type');
      if(this.user_type == 'Admin' || this.admin_permit == '1'){
        this.has_admin = true;
      } else {
        this.has_user = true;
      }
  
  
      this.has_hard_id = localStorage.getItem('hardware_id');
  
      if(this.has_hard_id == ""){
          // $('#addLicence').modal('show');
          $("#addLicence").modal({"backdrop": "static"});
          this.show_act_wall = true;
      } else {
        this.initSocket();
      }
  
     
    }
  
    
    ngAfterViewInit(){
     
    }
  
  
  initSocket(){
  var self=this;
  
  
    
    this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4043");  
    this.websocket.onopen = function(event) { 
      $('#sendonload').click();
      console.log('common wallboard socket connected');
    }
  
    this.websocket.onmessage = function(event) {
   var result_message = JSON.parse(event.data);
   console.log(result_message); 
   if (result_message[0].cust_id == self.has_hard_id) {
   console.log('matched');
   } else {
     // console.log('not matched');
     return false;
   }
   
   if (result_message[0].data[0].type != undefined) {
    $('#mc_event_list').val(event.data); 
    $('#mc_event_list').click();
  }
 
    }
    this.websocket.onerror = function(event){
      console.log('error');
    }
    this.websocket.onclose = function(event){
      console.log('close');
    } 
  }
  
  
    sendOnload(){
      var socket_message  =  '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"onload","Type":"wallboard_gdex"}]}]' ;
      this.websocket.send(socket_message);
     }
  
  changeData(){
  
    let socketData = $('#mc_event_list').val(); 
  
    let mData = JSON.parse(socketData);
    this.socketData = mData[0];  
 
    if(this.socketData.data[0].type == "abandoned"){
      this.abandoned = this.socketData.data[0].value;
    } 
  
    if(this.socketData.data[0].type == "drop"){
      this.droped = this.socketData.data[0].value;
    }  
    
  
  }
  
 
  
    fullWindow(){
    var element = document.getElementById("wallboardFullPanel");
    element.classList.toggle("fullSize");
    }
  
  
  
  
    checkLicenseKey(){
      let access_token: any=localStorage.getItem('access_token');
  
      let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_hardware","user_id":"'+this.loginUser+'"}}';
      this.serverService.sendServer(api_req).subscribe((response:any) => {
        if(response.result.data.value=='1'){
          this.initSocket();            
        } else {
          iziToast.error({
            message: "Your Licence Key is expired!.. please enter your key or contact admin",
            position: 'topRight'
            });
            $("#addLicence").modal({"backdrop": "static"});
            this.show_act_wall = true;
        }
      }, 
      (error)=>{
          console.log(error);
      });
    }
  
  
  
    activateLicenseKey(){
      let access_token: any=localStorage.getItem('access_token');
      let l_key: any=$('#licence_key').val();
      if(l_key == ""){
        iziToast.error({
          message: "Please enter the licence key",
          position: 'topRight'
          });
          return false;
      }
      let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_license","user_id":"'+this.loginUser+'","license_key":"'+l_key+'"}}';
      this.serverService.sendServer(api_req).subscribe((response:any) => {
        if(response.result.data.value==1){
          localStorage.setItem('hardware_id', response.result.data.hardware_id);
          this.initSocket();
            iziToast.success({
              message: "Wallboard activated",
              position: 'topRight'
              });
              $("#addLicence").modal('hide');
              
        } else {
          iziToast.error({
            message: "Please enter a valid key",
            position: 'topRight'
            });
           
        }
      }, 
      (error)=>{
          console.log(error);
      });
    }
  
  
    showdoc(link){   
      this.doc_link=link;
     $("#document_model").modal('show');   
    }
    
  
  
  }
  